var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [
      _c("white-loader", { attrs: { loading: _vm.loading } }),
      _c("div", { staticStyle: { position: "relative" } }, [
        _c(
          "header",
          {
            staticStyle: {
              background:
                "linear-gradient(180deg, rgba(0, 0, 0, 0.80) 3.59%, rgba(133, 11, 11, 0.80) 48.02%)",
              padding: "11px 25px 11px 25px",
            },
          },
          [
            _c("h1", { staticClass: "welcome-message" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("default.HOME_WELCOME_MESSAGE")) + " "
              ),
            ]),
          ]
        ),
        _c("div", { staticClass: "wrap-home-resources" }, [
          _c("div", { staticClass: "home-resources-content" }, [
            _c("div", { staticClass: "container-fluid" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c(
                    "div",
                    {
                      staticClass: "carousel-home-resources position-relative",
                      attrs: { id: "carousel-home-resources" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "w-100 px-3 pt-5 pt-sm-4 mx-auto position-absolute d-flex justify-content-start",
                          staticStyle: {
                            "z-index": "9",
                            left: "50%",
                            top: "5%",
                            transform: "translate(-50%, -50%)",
                            "max-width": "700px",
                          },
                        },
                        [
                          _c(
                            "mdb-btn",
                            {
                              staticClass: "spainhn-button",
                              staticStyle: {
                                "background-color": "#ff0000 !important",
                              },
                              attrs: {
                                tag: "a",
                                href: "https://www.youtube.com/watch?v=wR1iYYlnliw&ab_channel=ReddeCastillosyPalaciosdeEspa%C3%B1a",
                                target: "_blank",
                                size: "sm",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "fs-6 text-white",
                                attrs: { icon: "fa-play" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "carousel-3d",
                        {
                          attrs: {
                            perspective: 36,
                            border: 0,
                            width: 700,
                            height: 450,
                            "controls-visible": true,
                            space: 530,
                            autoplay: "",
                            autoplayTimeout: 5000,
                            count: _vm.imagenes.length,
                            display:
                              _vm.imagenes.length > 4 ? 5 : _vm.imagenes.length,
                          },
                        },
                        _vm._l(_vm.imagenes, function (img, index) {
                          return _c("slide", {
                            key: index,
                            staticStyle: { "border-radius": "10px" },
                            attrs: { index: index },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({
                                    index,
                                    isCurrent,
                                    leftIndex,
                                    rightIndex,
                                  }) {
                                    return [
                                      _c("img", {
                                        staticClass: "slider-img-2",
                                        class: {
                                          current: isCurrent,
                                          onLeft: leftIndex >= 0,
                                          onRight: rightIndex >= 0,
                                        },
                                        attrs: {
                                          "data-index": index,
                                          src: img.url,
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "px-0",
            staticStyle: { position: "absolute", top: "65%", width: "100%" },
          },
          [
            _c(
              "mdb-container",
              { staticClass: "pt-md-4 pt-3" },
              [
                _c(
                  "BuscadorNewDesign",
                  {
                    ref: "pill",
                    staticClass: "mx-auto",
                    staticStyle: { width: "540px", "max-width": "85vw" },
                    attrs: {
                      disabled: _vm.disabledBtn,
                      tooltip: _vm.tooltip,
                      warningInfo: _vm.warningInfo,
                      changeColorBtn: _vm.changeColorBtn,
                      value: _vm.buscadorInput,
                    },
                    on: {
                      "auto-hide-warning": _vm.closeWarning,
                      submit: _vm.submit,
                      input: _vm.updateInput,
                      "update-value": (evt) => _vm.updateBuscadorInput(evt),
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "w-100" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex w-100 justify-content-center align-content-center",
                          },
                          [
                            _c(
                              "h6",
                              {
                                staticClass: "mr-1",
                                staticStyle: { "text-wrap": "balance" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("default.FILTER.SELECT_DATE_RANGE")
                                  ) + " "
                                ),
                              ]
                            ),
                            _c("font-awesome-icon", {
                              class:
                                _vm.range?.start && _vm.range?.end
                                  ? "color-corp"
                                  : "text-light",
                              staticStyle: {
                                cursor: "pointer",
                                transition: "all 0.3s",
                              },
                              attrs: { icon: "fa-broom" },
                              on: { click: _vm.limpiarBuscador },
                            }),
                          ],
                          1
                        ),
                        _c("vc-date-picker", {
                          staticClass: "color-corp",
                          attrs: {
                            "is-range": "",
                            color: "red",
                            "min-date": new Date(),
                          },
                          model: {
                            value: _vm.range,
                            callback: function ($$v) {
                              _vm.range = $$v
                            },
                            expression: "range",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "mdb-container",
        {
          staticClass: "mx-auto",
          staticStyle: { "padding-inline": "12px" },
          attrs: { id: "results" },
        },
        [
          _c("ToolsComponent", {
            staticStyle: {
              width: "100%",
              "max-width": "780px",
              "margin-left": "auto",
              "margin-right": "auto",
              "border-radius": "10px",
              overflow: "hidden",
            },
          }),
        ],
        1
      ),
      _vm.loading
        ? _c("div", { staticStyle: { "min-height": "500px", height: "100%" } })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "container-fluid pt-4 px-1 px-md-3" },
        [
          _c(
            "mdb-row",
            { staticClass: "gy-3" },
            [
              _c(
                "mdb-col",
                { staticClass: "order-3", attrs: { col: "12" } },
                [
                  _c("h3", { staticClass: "spainhn-subtitle text-uppercase" }, [
                    _vm._v(_vm._s(_vm.$t("default.HOME_NUESTROS_MONUMENTOS"))),
                  ]),
                  _vm._l(_vm.availableTiposDeMonumento, function (tipo) {
                    return _c(
                      "mdb-container",
                      {
                        key: tipo.value,
                        staticClass: "px-0 px-md-2",
                        attrs: { fluid: "" },
                      },
                      [
                        _vm.getMatchingTipoMonumentos(tipo.value).length > 0
                          ? _c(
                              "div",
                              [
                                _c("hr", { staticClass: "m-0 mt-1 mx-3" }),
                                _c(
                                  "mdb-badge",
                                  {
                                    staticClass:
                                      "spainhn-header-info text-uppercase",
                                    staticStyle: {
                                      "background-color":
                                        "var(--spainhn-rojo-spainhn) !important",
                                    },
                                  },
                                  [
                                    _vm._v(" " + _vm._s(tipo.webName) + " "),
                                    _c("font-awesome-icon", {
                                      class: { "d-none": _vm.sugerencias },
                                      staticStyle: {
                                        width: "20px",
                                        height: "20px",
                                      },
                                      attrs: { icon: "fa fa-location-dot" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-md-block d-none mb-4" },
                                  [
                                    !_vm.clorian
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-around px-0 mx-auto w-100 flex-wrap",
                                          },
                                          _vm._l(
                                            _vm.getMatchingTipoMonumentos(
                                              tipo.value
                                            ),
                                            function (value, index) {
                                              return _c("Cards2", {
                                                key: index,
                                                attrs: {
                                                  monument: value.webName,
                                                  provincia: value.provincia,
                                                  municipio: value.municipio,
                                                  imageUrl: `${_vm.imagenesUrl}/monumentos/${value.oldId}/${value.banner}`,
                                                  id: value._id,
                                                  nombreUrl:
                                                    value.nombreNormalizado,
                                                  text: value.webDescription,
                                                  role: "button",
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c(
                                  "mdb-row",
                                  { staticClass: "mx-auto d-md-none" },
                                  _vm._l(
                                    _vm.getMatchingTipoMonumentos(tipo.value),
                                    function (value, index) {
                                      return _c(
                                        "mdb-col",
                                        { key: index, staticClass: "px-0" },
                                        [
                                          _c("CardsGeneral", {
                                            staticClass: "mx-auto",
                                            attrs: {
                                              subtitle: `${value.provincia}, ${value.municipio}`,
                                              title: value.webName,
                                              imageUrl: `${_vm.imagenesUrl}/monumentos/${value.oldId}/${value.banner}`,
                                              type: "monumento",
                                            },
                                            on: {
                                              goToUrl: function ($event) {
                                                return _vm.goToCastillos(
                                                  value.nombreNormalizado
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                                _c(
                                  "mdb-row",
                                  { staticClass: "mx-auto d-md-none" },
                                  [
                                    _c("mdb-col", { attrs: { col: "12" } }, [
                                      tipo.value === "Castillos"
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "read-more-btn spainhn-gray-background mx-auto mb-1",
                                              on: {
                                                click: () =>
                                                  _vm.toggleFullCastles(),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s("Cargar más") + " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                ],
                2
              ),
              _c(
                "mdb-col",
                { staticClass: "order-1", attrs: { col: "12" } },
                [
                  _c("h3", { staticClass: "spainhn-subtitle text-uppercase" }, [
                    _vm._v(_vm._s(_vm.$t("default.HOME_QUE_HACER_Y_VER"))),
                  ]),
                  _c(
                    "mdb-container",
                    { staticClass: "px-0 px-md-2", attrs: { fluid: "" } },
                    [
                      _vm.visitas.length
                        ? _c(
                            "div",
                            { staticClass: "py-2" },
                            [
                              _c("hr", { staticClass: "m-0 mt-1 mb-2 mx-3" }),
                              _c(
                                "mdb-badge",
                                {
                                  staticClass: "spainhn-header-info text-start",
                                  staticStyle: {
                                    "background-color":
                                      "var(--spainhn-rojo-spainhn) !important",
                                  },
                                  attrs: { color: "danger" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        !_vm.sugerencias
                                          ? _vm.$t(
                                              "default.HOME_RESULTS_SINGLE_VISITS_LABEL"
                                            )
                                          : _vm.$t(
                                              "default.HOME_RESULTS_VISITS_LABEL"
                                            )
                                      ) +
                                      " "
                                  ),
                                  _c("font-awesome-icon", {
                                    class: { "d-none": _vm.sugerencias },
                                    staticStyle: {
                                      width: "20px",
                                      height: "20px",
                                    },
                                    attrs: { icon: "fa-location-dot" },
                                  }),
                                ],
                                1
                              ),
                              !_vm.clorian
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-md-flex d-none justify-content-around px-0 mx-auto w-100 flex-wrap",
                                    },
                                    _vm._l(_vm.visitas, function (visita) {
                                      return _c("ResultsCards", {
                                        key: visita._id,
                                        attrs: {
                                          entradaId: visita._id,
                                          monumentoNombreURL: visita.webName,
                                          imageUrl: `${_vm.imagenesUrl}/visitas_castillos/${visita.oldId}/${visita.banner}`,
                                          title: visita.webName,
                                          monument: visita.monumentName,
                                          apiDeVentasUrl:
                                            visita.apiDeVentasActive
                                              ? `${visita.apiDeVentasUrl}?idProducto=${visita.oldId}`
                                              : "",
                                          precioOrientativo:
                                            visita?.precioOrientativo
                                              ? _vm.$t(
                                                  "default.CARDS.START_FROM",
                                                  {
                                                    msg: _vm.getPrice(
                                                      visita?.precioOrientativo
                                                    ),
                                                  }
                                                )
                                              : "",
                                        },
                                        on: {
                                          goToEntrada: (evt) =>
                                            _vm.goToEntrada({
                                              entradaId: evt.entradaId,
                                              monumentoUrl: visita.monumentUrl,
                                            }),
                                        },
                                      })
                                    }),
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "mdb-row",
                                {
                                  staticClass: "mx-auto d-md-none",
                                  class: {
                                    "card-container": !_vm.showAllVisits,
                                  },
                                },
                                _vm._l(_vm.visitas, function (visita) {
                                  return _c(
                                    "mdb-col",
                                    { key: visita._id, staticClass: "px-0" },
                                    [
                                      _c("CardsGeneral", {
                                        staticClass: "mx-auto",
                                        attrs: {
                                          title: visita.webName,
                                          subtitle: `${visita.municipio}, ${visita.provincia}`,
                                          bold: true,
                                          muted: true,
                                          content: `${
                                            visita.monumentName ||
                                            visita.comunidad
                                          }`,
                                          "image-url": `${_vm.imagenesUrl}/visitas_castillos/${visita.oldId}/${visita.banner}`,
                                          buyBtn: true,
                                        },
                                        on: {
                                          goToBuy: function ($event) {
                                            return _vm.goToEntrada({
                                              entradaId: visita._id,
                                              monumentoUrl: visita.monumentUrl,
                                            })
                                          },
                                          goToUrl: () =>
                                            _vm.goToBuy({
                                              entradaId: visita._id,
                                              oldId: visita.oldId,
                                              apiDeVentasActive:
                                                visita.apiDeVentasActive,
                                              apiDeVentasUrl:
                                                visita.apiDeVentasUrl,
                                            }),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                              _c(
                                "mdb-row",
                                {
                                  staticClass: "mx-auto d-md-none",
                                  class: { "d-none": _vm.visitas.length === 0 },
                                },
                                [
                                  _c("mdb-col", { attrs: { col: "12" } }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "read-more-btn spainhn-gray-background mx-auto mb-0",
                                        on: {
                                          click: () => _vm.toggleFullVisits(),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              !_vm.showAllVisits
                                                ? _vm.$t(
                                                    "default.HOME_RESULTS_MORE_VISITS2"
                                                  )
                                                : _vm.$t(
                                                    "default.HOME_RESULTS_LESS_VISITS2"
                                                  )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "mdb-container",
                    { staticClass: "px-0 px-md-2", attrs: { fluid: "" } },
                    [
                      _vm.eventos2?.length
                        ? _c(
                            "div",
                            { staticClass: "py-2" },
                            [
                              _c("hr", { staticClass: "m-0 mt-1 mb-2 mx-3" }),
                              _c(
                                "mdb-badge",
                                {
                                  staticClass: "spainhn-header-info",
                                  staticStyle: {
                                    "background-color":
                                      "var(--spainhn-rojo-spainhn) !important",
                                  },
                                  attrs: { color: "danger" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        !_vm.sugerencias
                                          ? _vm.$t(
                                              "default.HOME_RESULTS_SINGLE_EVENTS_LABEL"
                                            )
                                          : _vm.$t(
                                              "default.HOME_RESULTS_EVENTS_LABEL"
                                            )
                                      ) +
                                      " "
                                  ),
                                  _c("font-awesome-icon", {
                                    class: { "d-none": _vm.sugerencias },
                                    staticStyle: {
                                      width: "20px",
                                      height: "20px",
                                    },
                                    attrs: { icon: "fa-location-dot" },
                                  }),
                                ],
                                1
                              ),
                              !_vm.clorian
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-md-flex d-none justify-content-around px-0 mx-auto w-100 flex-wrap",
                                    },
                                    _vm._l(
                                      _vm.eventos2,
                                      function (visita, index) {
                                        return _c("ResultsCards", {
                                          key: index,
                                          attrs: {
                                            entradaId: visita._id,
                                            monumentoNombreURL: visita.webName,
                                            imageUrl: `${_vm.imagenesUrl}/visitas_castillos/${visita.oldId}/${visita.banner}`,
                                            title: visita.webName,
                                            monument: visita.monumentName,
                                            precioOrientativo:
                                              visita?.precioOrientativo &&
                                              _vm.$t(
                                                "default.CARDS.START_FROM",
                                                {
                                                  msg: _vm.getPrice(
                                                    visita?.precioOrientativo
                                                  ),
                                                }
                                              ),
                                            apiDeVentasUrl:
                                              visita.apiDeVentasActive
                                                ? `${visita.apiDeVentasUrl}?idProducto=${visita.oldId}`
                                                : "",
                                            tipo: "Evento",
                                            "prox-date":
                                              visita.fechaInicioEvento,
                                            date: visita.fechas,
                                          },
                                          on: {
                                            goToEntrada: (evt) =>
                                              _vm.goToEntrada({
                                                entradaId: evt.entradaId,
                                                monumentoUrl:
                                                  visita.monumentUrl,
                                              }),
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "mdb-row",
                                {
                                  staticClass: "mx-auto d-md-none",
                                  class: {
                                    "card-container": !_vm.showAllEvents,
                                  },
                                },
                                _vm._l(_vm.eventos2, function (visita, index) {
                                  return _c(
                                    "mdb-col",
                                    { key: index, staticClass: "px-0" },
                                    [
                                      _c("CardsGeneral", {
                                        staticClass: "mx-auto",
                                        attrs: {
                                          title: visita.webName,
                                          subtitle: `${visita.municipio}, ${visita.provincia}`,
                                          bold: true,
                                          muted: true,
                                          content: `${
                                            visita.monumentName ||
                                            visita.comunidad
                                          }`,
                                          "image-url": `${_vm.imagenesUrl}/visitas_castillos/${visita.oldId}/${visita.banner}`,
                                        },
                                        on: {
                                          goToUrl: () =>
                                            _vm.goToEntrada({
                                              entradaId: visita._id,
                                              monumentoUrl: visita.monumentUrl,
                                            }),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                              _c(
                                "mdb-row",
                                {
                                  staticClass: "mx-auto d-md-none",
                                  class: { "d-none": _vm.eventos2.length == 0 },
                                },
                                [
                                  _c("mdb-col", { attrs: { col: "12" } }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "read-more-btn spainhn-gray-background mx-auto mb-0",
                                        on: {
                                          click: () => _vm.toggleFullEvents(),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "default.HOME_RESULTS_MORE_VISITS"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "mdb-col",
                { staticClass: "order-2", attrs: { col: "12" } },
                [
                  _c(
                    "h3",
                    {
                      staticClass: "spainhn-subtitle text-uppercase",
                      class: {
                        "d-none":
                          _vm.paquetes.length === 0 &&
                          _vm.servicios.length === 0 &&
                          _vm.paquetesMonumentos.length === 0,
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("default.HOME_NUESTROS_PAQUETES")) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "mdb-container",
                    { staticClass: "px-0 px-md-2", attrs: { fluid: "" } },
                    [
                      _vm.paquetes.length > 0 ||
                      _vm.servicios.length > 0 ||
                      _vm.paquetesMonumentos.length > 0
                        ? _c(
                            "div",
                            { staticClass: "py-2" },
                            [
                              _c("hr", { staticClass: "m-0 mt-1 mb-2 mx-3" }),
                              _c(
                                "mdb-badge",
                                {
                                  staticClass: "spainhn-header-info",
                                  staticStyle: {
                                    "background-color":
                                      "var(--spainhn-rojo-spainhn) !important",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "default.HOME_RESULTS.SERVICIOS_TITLE"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("font-awesome-icon", {
                                    class: { "d-none": _vm.sugerencias },
                                    staticStyle: {
                                      width: "20px",
                                      height: "20px",
                                    },
                                    attrs: { icon: "fa-location-dot" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-md-flex d-none justify-content-around px-0 mx-auto w-100 flex-wrap",
                                },
                                [
                                  [
                                    _vm._l(
                                      _vm.paquetesMonumentos,
                                      function (paqueteM) {
                                        return _c("ResultsCards", {
                                          key: paqueteM._id,
                                          staticClass: "mx-auto",
                                          attrs: {
                                            title: paqueteM.webName,
                                            entradaId: paqueteM._id,
                                            showDefault: false,
                                            monumentoNombreURL:
                                              paqueteM.nombreNormalizado,
                                            monument: _vm.$t(
                                              "default.CARDS.PLACE",
                                              {
                                                msg: `${paqueteM.monumentName}`,
                                              }
                                            ),
                                            precioOrientativo:
                                              paqueteM?.precioOrientativo
                                                ? _vm.$t(
                                                    "default.CARDS.START_FROM",
                                                    {
                                                      msg: `${parseFloat(
                                                        Number(
                                                          paqueteM?.precioOrientativo
                                                        )
                                                      )?.toLocaleString(
                                                        "es-ES",
                                                        {
                                                          style: "currency",
                                                          currency: "EUR",
                                                        }
                                                      )}`,
                                                    }
                                                  )
                                                : "",
                                            imageUrl: `${_vm.imagenesUrl}/paquetes_monumentos/${paqueteM.oldId}/${paqueteM.banner}`,
                                            tipo: "paquetes_monumentos",
                                          },
                                          on: {
                                            goToEntrada: () =>
                                              _vm.goToMicrositioPaqueteMonumento(
                                                {
                                                  name: paqueteM.name,
                                                  id: paqueteM._id,
                                                }
                                              ),
                                            buy: () =>
                                              _vm.goToBuy(paqueteM, true),
                                          },
                                        })
                                      }
                                    ),
                                    _vm._l(
                                      _vm.paquetes.concat(_vm.servicios),
                                      function (product) {
                                        return _c("ResultsCards", {
                                          key: product._id,
                                          staticClass: "mx-auto",
                                          attrs: {
                                            title:
                                              product.webName ||
                                              product.nombreServicio,
                                            showDefault: false,
                                            entradaId: product._id,
                                            monumentoNombreURL: product.webName,
                                            text: _vm.$t(
                                              "default.CARDS_PLACE",
                                              { msg: product.place }
                                            ),
                                            monument: _vm.$t(
                                              "default.CARDS_PLACE",
                                              { msg: product.place }
                                            ),
                                            tipo: "paquetes_prestadores",
                                            precioOrientativo:
                                              product?.precioOrientativo &&
                                              _vm.$t(
                                                "default.CARDS.START_FROM",
                                                {
                                                  msg: `${parseFloat(
                                                    Number(
                                                      product?.precioOrientativo
                                                    )
                                                  )?.toLocaleString("es-ES", {
                                                    style: "currency",
                                                    currency: "EUR",
                                                  })}`,
                                                }
                                              ),
                                            imageUrl: `${_vm.imagenesUrl}/${
                                              product.imageLabel
                                            }/${
                                              product.oldId
                                                ? product.oldId +
                                                  "/" +
                                                  encodeURI(product.banner)
                                                : product.prestadorId +
                                                  "/" +
                                                  product.banner
                                            }`,
                                          },
                                          on: {
                                            goTo: function ($event) {
                                              return _vm.goToEntrada(product)
                                            },
                                            goToEntrada: () =>
                                              _vm.goToEntrada(product),
                                            buy: () => _vm.goToBuy(product),
                                          },
                                        })
                                      }
                                    ),
                                  ],
                                ],
                                2
                              ),
                              _c(
                                "mdb-row",
                                {
                                  staticClass: "mx-auto d-md-none",
                                  class: {
                                    "card-container": !_vm.showAllVisits,
                                  },
                                },
                                [
                                  _vm._l(
                                    _vm.paquetesMonumentos,
                                    function (paqueteM, index) {
                                      return _c(
                                        "mdb-col",
                                        { key: index, staticClass: "px-0" },
                                        [
                                          _c("CardsGeneral", {
                                            staticClass: "mx-auto",
                                            attrs: {
                                              title: paqueteM.name,
                                              subtitle: `${paqueteM.monumentoId?.comunidad}`,
                                              bold: true,
                                              muted: true,
                                              content: `${paqueteM.webName}`,
                                              "image-url": `${_vm.imagenesUrl}/paquetes_monumentos/${paqueteM.oldId}/${paqueteM.banner}`,
                                              buyBtn: true,
                                              type: "paquetes_monumentos",
                                            },
                                            on: {
                                              goToBuy: () =>
                                                _vm.goToMicrositioPaqueteMonumento(
                                                  {
                                                    name: paqueteM.name,
                                                    id: paqueteM._id,
                                                  }
                                                ),
                                              goToUrl: () =>
                                                _vm.goToBuy(paqueteM, true),
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm._l(
                                    _vm.paquetes.concat(_vm.servicios),
                                    function (producto) {
                                      return _c(
                                        "mdb-col",
                                        {
                                          key: producto._id,
                                          staticClass: "px-0",
                                        },
                                        [
                                          _c("CardsGeneral", {
                                            staticClass: "mx-auto",
                                            attrs: {
                                              title: producto.nombres.find(
                                                (item) =>
                                                  item.idioma ===
                                                  _vm.$i18n.locale
                                              )?.texto,
                                              subtitle: `${producto.municipio}, ${producto.provincia}`,
                                              bold: true,
                                              muted: true,
                                              content: `${
                                                producto.monumentName ||
                                                producto.comunidad
                                              }`,
                                              "image-url": `${_vm.imagenesUrl}/visitas_castillos/${producto.oldId}/${producto.banner}`,
                                              type: "paquetes_monumentos",
                                              buyBtn: true,
                                            },
                                            on: {
                                              goToBuy: () =>
                                                _vm.goToEntrada(producto),
                                              goToUrl: () =>
                                                _vm.goToBuy(producto),
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "mdb-col",
                { staticClass: "order-3", attrs: { col: "12" } },
                [
                  _c("h3", { staticClass: "spainhn-subtitle text-uppercase" }, [
                    _vm._v(_vm._s(_vm.$t("default.HOME_NUESTRAS_RUTAS"))),
                  ]),
                  _c(
                    "mdb-container",
                    { staticClass: "px-0 px-md-2", attrs: { fluid: "" } },
                    [
                      _vm.rutas
                        ? _c(
                            "div",
                            { staticClass: "py-2" },
                            [
                              _c("hr", { staticClass: "m-0 mt-1 mb-2 mx-3" }),
                              _c(
                                "mdb-badge",
                                {
                                  staticClass: "spainhn-header-info",
                                  staticStyle: {
                                    "background-color":
                                      "var(--spainhn-rojo-spainhn) !important",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        !_vm.sugerencias
                                          ? _vm.$t(
                                              "default.HOME_RESULTS_NEAR_ROUTES_LABEL"
                                            )
                                          : _vm.$t(
                                              "default.HOME_RESULTS_ROUTES_LABEL"
                                            )
                                      ) +
                                      " "
                                  ),
                                  _c("font-awesome-icon", {
                                    class: { "d-none": _vm.sugerencias },
                                    staticStyle: {
                                      width: "20px",
                                      height: "20px",
                                    },
                                    attrs: { icon: "fa-location-dot" },
                                  }),
                                ],
                                1
                              ),
                              !_vm.clorian
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-md-flex d-none justify-content-around px-0 mx-auto w-100 flex-wrap",
                                    },
                                    _vm._l(_vm.rutas, function (ruta, index) {
                                      return _c("Cards3", {
                                        key: index,
                                        attrs: {
                                          showSubtitle: false,
                                          title: ruta.webName,
                                          "image-url": `${_vm.imagenesUrl}/rutas/logo/${ruta?.rutaId}`,
                                        },
                                        on: {
                                          goTo: () => _vm.goToRoute(ruta._id),
                                        },
                                      })
                                    }),
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "mdb-row",
                                {
                                  staticClass: "mx-auto d-md-none",
                                  class: {
                                    "card-container": !_vm.showAllRoutes,
                                  },
                                },
                                _vm._l(_vm.rutas, function (ruta, index) {
                                  return _c(
                                    "mdb-col",
                                    { key: index, staticClass: "px-0" },
                                    [
                                      _c("CardsGeneral", {
                                        staticClass: "mx-auto",
                                        attrs: {
                                          title: ruta.webName,
                                          bold: true,
                                          muted: true,
                                          content: `${
                                            ruta.duracion
                                              ? ruta.duracion?.find(
                                                  (item) =>
                                                    item.idioma ===
                                                    _vm.$i18n.locale
                                                )?.texto
                                              : "1 día"
                                          }`,
                                          "image-url": `${_vm.imagenesUrl}/rutas/logo/${ruta.rutaId}`,
                                        },
                                        on: {
                                          goToUrl: () =>
                                            _vm.goToRoute(ruta._id),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                              _c(
                                "mdb-row",
                                {
                                  staticClass: "mx-auto d-md-none",
                                  class: { "d-none": _vm.rutas.length == 0 },
                                },
                                [
                                  _c("mdb-col", { attrs: { col: "12" } }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "read-more-btn spainhn-gray-background mx-auto mb-1",
                                        on: {
                                          click: () => _vm.toggleFullRoutes(),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "default.HOME_RESULTS_MORE_ROUTES"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "container-fluid" }, [
        _c("hr"),
        _vm.isMobile === false
          ? _c("div", { staticClass: "mt-3 d-none d-md-block" }, [
              _c(
                "div",
                [
                  _c("MiniMap", {
                    staticClass: "d-none d-md-block",
                    attrs: { variant: false },
                  }),
                ],
                1
              ),
            ])
          : _c(
              "div",
              { staticClass: "mt-3" },
              [
                _c("MiniMap", {
                  staticClass: "d-md-none mx-auto",
                  attrs: { idFilter: "home-filter" },
                }),
              ],
              1
            ),
      ]),
      _c("div", { staticClass: "container-fluid p-0 slider-cards" }, [
        _c(
          "div",
          { staticClass: "row m-0", staticStyle: { "min-height": "70vh" } },
          [
            _c(
              "div",
              {
                staticClass:
                  "col-xxl-9 col-12 justify-content-center px-3 order-last order-xxl-first py-4 visitas-container",
                staticStyle: { "min-height": "70vh" },
              },
              [
                _c(
                  "div",
                  { staticClass: "pt-1" },
                  [
                    _c("slider", {
                      staticClass: "my-3",
                      attrs: {
                        tipo: "Evento",
                        carouselId: "eventos33",
                        images: _vm.eventosClusters,
                      },
                    }),
                    _c(
                      "h3",
                      {
                        staticClass: "spainhn-title my-auto",
                        class: { "d-none": _vm.eventos.length },
                      },
                      [_vm._v("Aún no hay próximas visitas")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "spainhn-solid-red-background col-xxl-3 px-4 px-md-5 order-first order-xxl-last pt-5 pb-3 col-12",
              },
              [
                _c(
                  "h1",
                  { staticClass: "spainhn-title-text-2 text-start pt-md-5" },
                  [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("default.HOME_NEXT_EVENTS_TITLE"))),
                    ]),
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "spainhn-text text-start pe-md-5",
                    staticStyle: { color: "white" },
                  },
                  [_vm._v(_vm._s(_vm.$t("default.HOME_EVENTS_DESCRIPTION")))]
                ),
                _c(
                  "mdb-btn",
                  {
                    staticClass: "spainhn-button-gray ms-4 mt-2 text-white",
                    attrs: { color: "indigo", size: "lg" },
                    on: { click: _vm.goToEventos },
                  },
                  [
                    _c("strong", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("default.HOME_ALL_EVENTS_BUTTON")) +
                          " "
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "container-fluid p-0 slider-cards" }, [
        _c(
          "div",
          { staticClass: "row m-0", staticStyle: { "min-height": "70vh" } },
          [
            _c(
              "div",
              {
                staticClass:
                  "spainhn-solid-red-background col-12 col-xxl-3 pt-5 pb-3 px-md-5 px-4",
              },
              [
                _c(
                  "h1",
                  { staticClass: "spainhn-title-text-2 text-end pt-md-5" },
                  [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$t("default.HOME_NEXT_EXPERIENCES_TITLE"))
                      ),
                    ]),
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "spainhn-text text-end ps-md-5",
                    staticStyle: { color: "white" },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("default.HOME_EXPERIENCES_DESCRIPTION_1"))
                    ),
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "spainhn-text text-end ps-md-5",
                    staticStyle: { color: "white" },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("default.HOME_EXPERIENCES_DESCRIPTION_2"))
                    ),
                  ]
                ),
                _c(
                  "mdb-btn",
                  {
                    staticClass: "spainhn-button-gray ms-4 mt-2 text-white",
                    attrs: { color: "indigo", size: "lg" },
                    on: { click: _vm.goToExperiences },
                  },
                  [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$t("default.HOME_ALL_EXPERIENCES_BUTTON"))
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xxl-9 col-12 justify-content-center px-3 py-4 visitas-container",
                staticStyle: { "min-height": "70vh" },
              },
              [
                _c(
                  "div",
                  { staticClass: "pt-1 mt-2" },
                  [
                    _c("ul", { staticClass: "carousel-indicators bg-danger" }, [
                      _c("li", {
                        staticClass: "active",
                        attrs: {
                          "data-target": "#experiencias",
                          "data-slide-to": "0",
                        },
                      }),
                      _c("li", {
                        attrs: {
                          "data-target": "#experiencias",
                          "data-slide-to": "1",
                        },
                      }),
                      _c("li", {
                        attrs: {
                          "data-target": "#experiencias",
                          "data-slide-to": "2",
                        },
                      }),
                    ]),
                    _c("slider", {
                      staticClass: "my-3",
                      attrs: {
                        tipo: "Experiencia",
                        carouselId: "experiencias",
                        images: _vm.experienciasClusters,
                      },
                    }),
                    _c(
                      "h3",
                      {
                        staticClass: "spainhn-title my-auto",
                        class: { "d-none": _vm.experiencias.length },
                      },
                      [_vm._v(" Aún no hay próximas experiencias ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "mt-3" },
        [_c("ComponentsCloud", { staticClass: "p-0" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }